<template>
    <a-layout>
        <a-layout-sider v-if="winwidth>winwidthmodelimit&&!ismobile" v-model:collapsed="collapsed" :width="250" :trigger="null" collapsible class="sidemenucontainer">
            <fep-sidemenus :selectedkey="$uimenuselectedkey" v-model:collapsed="collapsed"/>
        </a-layout-sider>
        <a-drawer v-else width="60%" placement="left" :title="$langsmgr('langkey.sitename.short')" :open="drawstate" :closable="false" @close="closeDraw">
            <template #extra>
                <menu-fold-outlined class="trigger" @click="closeDraw"></menu-fold-outlined>
            </template>
            <fep-sidemenus :selectedkey="$uimenuselectedkey" :collapsed="true"/>
        </a-drawer>
        <a-layout-header class="headcontainer">
            <div class="headright">
                <span class="headmenu noselect">
                    <fep-headmenus/>
                </span>
            </div>
            <div v-if="winwidth>winwidthmodelimit&&!ismobile">
                <span :class="winwidth>winwidthmodelimit&&collapsed?'pcleftcollapsed':'pcleft'"></span>
                <menu-unfold-outlined v-if="collapsed" class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <menu-fold-outlined v-else class="trigger" @click="switchmenu"></menu-fold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
            <div v-else>
                <menu-unfold-outlined class="trigger" @click="switchmenu"></menu-unfold-outlined>
                <span style="margin-left:10px;color:#999999" class=noselect>{{$langsmgr(currentviewlankey)}}</span>
            </div>
        </a-layout-header>
        <a-layout-content class="acontent" :class="winwidth>winwidthmodelimit&&!ismobile?collapsed?'acontentlite':'acontentfull':null">
            <div :class="!ismobile?(winwidth>winwidthmodelimit?'vcontentlite':'vcontentfull'):'vcontentfull'">
                <a-row>
                    <a-col :span="24">
                        <div>
                            <a-input-search v-model:value="searchkey" :placeholder="$langsmgr('langkey.component.document.searchplaceholder')" @search="documentSearch" />
                        </div>
                        <a-card :title="$langsmgr('langkey.component.document.title.commonproblems')" :bordered="false" :size="ismobile?'small':'default'" class="rowcard"
                            :style="{borderRadius:'2px'}" :headStyle="{backgroundColor:'#f8f9fc'}"
                            :bodyStyle="{backgroundColor:'white',margin:0,padding:0}">
                            <a href="javascript:;" class="docitem" @click="documentShow(1012,$langsmgr('langkey.component.document.article.troubleshooting'))">
                                <div class="docitemtit">{{$langsmgr('langkey.component.document.article.troubleshooting')}}</div>
                                <div class="docitemdec">{{$langsmgr('langkey.component.document.tip.lasttime')}}2024/1/1</div>
                            </a>
                            <!-- <a-divider :style="{margin:0}"/>
                            <a href="javascript:;" class="docitem" @click="documentShow(1013,$langsmgr('langkey.component.document.article.troubleshooting'))">
                                <div class="docitemtit">{{$langsmgr('langkey.component.document.article.troubleshooting')}}</div>
                                <div class="docitemdec">{{$langsmgr('langkey.component.document.tip.lasttime')}}2023/09/20</div>
                            </a> -->
                        </a-card>
                        <a-card :title="$langsmgr('langkey.component.document.title.tutorial')" :bordered="false" :size="ismobile?'small':'default'" class="rowcard"
                            :style="{borderRadius:'2px'}" :headStyle="{backgroundColor:'#f8f9fc'}"
                            :bodyStyle="{backgroundColor:'white',margin:0,padding:0}">
                            <a href="javascript:;" class="docitem" @click="documentShow(1021,$langsmgr('langkey.component.document.article.android'))">
                                <div class="docitemtit">{{$langsmgr('langkey.component.document.article.android')}}</div>
                                <div class="docitemdec">{{$langsmgr('langkey.component.document.tip.lasttime')}}2024/1/1</div>
                            </a>
                            <a-divider :style="{margin:0}"/>
                            <a href="javascript:;" class="docitem" @click="documentShow(1022,$langsmgr('langkey.component.document.article.windows'))">
                                <div class="docitemtit">{{$langsmgr('langkey.component.document.article.windows')}}</div>
                                <div class="docitemdec">{{$langsmgr('langkey.component.document.tip.lasttime')}}2024/1/1</div>
                            </a>
                            <a-divider :style="{margin:0}"/>
                            <a href="javascript:;" class="docitem" @click="documentShow(1023,$langsmgr('langkey.component.document.article.ios'))">
                                <div class="docitemtit">{{$langsmgr('langkey.component.document.article.ios')}}</div>
                                <div class="docitemdec">{{$langsmgr('langkey.component.document.tip.lasttime')}}2024/1/1</div>
                            </a>
                            <!-- <a-divider :style="{margin:0}"/>
                            <a href="javascript:;" class="docitem" @click="documentShow(1024,$langsmgr('langkey.component.document.article.macos'))">
                                <div class="docitemtit">{{$langsmgr('langkey.component.document.article.macos')}}</div>
                                <div class="docitemdec">{{$langsmgr('langkey.component.document.tip.lasttime')}}2023/09/20</div>
                            </a> -->
                        </a-card>
                        <a-card :title="$langsmgr('langkey.component.document.title.tos')" :bordered="false" :size="ismobile?'small':'default'" class="rowcard"
                            :style="{borderRadius:'2px'}" :headStyle="{backgroundColor:'#f8f9fc'}"
                            :bodyStyle="{backgroundColor:'white',margin:0,padding:0}">
                            <a href="javascript:;" class="docitem" @click="documentShow(1086,$langsmgr('langkey.component.document.article.tos'))">
                                <div class="docitemtit">{{$langsmgr('langkey.component.document.article.tos')}}</div>
                                <div class="docitemdec">{{$langsmgr('langkey.component.document.tip.lasttime')}}20234/1/1</div>
                            </a>
                            <a-divider :style="{margin:0}"/>
                            <a href="javascript:;" class="docitem" @click="documentShow(1087,$langsmgr('langkey.component.document.article.aup'))">
                                <div class="docitemtit">{{$langsmgr('langkey.component.document.article.aup')}}</div>
                                <div class="docitemdec">{{$langsmgr('langkey.component.document.tip.lasttime')}}2024/1/1</div>
                            </a>
                        </a-card>
                    </a-col>
                </a-row>
                <Cntfooter/>
            </div>
            <div>
                <a-drawer width="80%" :title="document.title" placement="right" :closable="false" :open="documentshowstate" @close="documentClose">
                    <template #extra>
                        <CloseOutlined class="custclose" @click="documentClose"/>
                    </template>
                    <a-skeleton active :loading="document.loading">
                        <div v-html="document.content"></div>
                    </a-skeleton>
                </a-drawer>
            </div>
        </a-layout-content>
    </a-layout>
</template>
<script setup>
import {MenuFoldOutlined,MenuUnfoldOutlined,CaretDownOutlined,CloseOutlined} from '@ant-design/icons-vue'
import { ref,getCurrentInstance, onMounted } from 'vue'
import Cntfooter from './cntfooter.vue'
import axios from 'axios'
import {message} from 'ant-design-vue'
import useClipboard from 'vue-clipboard3'
import {Base64} from 'js-base64'

const currentviewlankey = ref('langkey.menuitem.document')
const winwidthmodelimit = 1320
const drawstate = ref(false)
const winwidth = ref(0)
const usubscrid = ref(null)

const loading_context = ref(true)
//setTimeout(()=>{loading_context.value=false},3000)

const ginstance = getCurrentInstance()
const gconfig = ginstance.appContext.config.globalProperties
const collapsed = ref(ginstance.appContext.config.globalProperties.$uimenucollapsed)
const ismobile = ref(ginstance.appContext.config.globalProperties.$ismobile())
const documentshowstate = ref(false)
const document = ref({
    loading: false,
    title: null,
    content: null
})
const {toClipboard} = useClipboard()

onMounted(()=>{
    whenWindowResize()
    window.addEventListener('resize', whenWindowResize)
    if(!gconfig.$getusubscrid())
        axios.get(`${gconfig.$backendbase}/dashboard/v3/useroverview`,{
            params:{r:Math.random()},
            headers: gconfig.$getauthheaders()
        }).then(resp=>{
            if(resp.data.data.result){
                usubscrid.value = resp.data.data.subscribeid
            }
        }).catch(err=>{
            gconfig.$axiosErrorHandle(err)
        })
    else usubscrid.value = gconfig.$getusubscrid()
})
const whenWindowResize=()=>{ winwidth.value = window.innerWidth }
const switchmenu = () => {
    if(!ismobile.value&&winwidth.value>winwidthmodelimit) ginstance.appContext.config.globalProperties.$uimenucollapsed = collapsed.value = !collapsed.value
    else showDraw()
}
const showDraw = () => { drawstate.value = true }
const closeDraw = () => { drawstate.value = false }

const documentShow = (id,title) => {
    document.value.title = title
    document.value.loading = true
    axios.get(`${gconfig.$docspath}/docs/${gconfig.$vlangcode}/${id}.cnt`)
        .then(resp=>{
            var __cnt = resp.data
            __cnt = __cnt.replace(/\{PROJNAME\}/g, gconfig.$sitename)
            __documentshow(title,__cnt)
        }).catch(err=>gconfig.$axiosErrorHandle(err))
}
const documentClose = () => { 
    document.value.title = null
    document.value.content = null
    documentshowstate.value = false
}

const __documentshow = (title,content) => {
    document.value.content = content
    document.value.loading = false
    documentshowstate.value = true
}

window.$importtoclash = async()=>{
    var uri = `${gconfig.$subscruribase}/v3/subscr?id=${usubscrid.value}`
    window.open(`clash://install-config?url=${encodeURIComponent(uri)}`,'_self')
}
window.$copymysubscraddr = async(data)=>{
    var subscrpath = `${gconfig.$subscruribase}/v3/subscr?id=${usubscrid.value}`
    try{ 
        await toClipboard(subscrpath)
        message.success('订阅地址复制成功')
    } catch { message.error('订阅地址复制失败') }
}
window.$gotocfagithub = () => {
    window.open('https://github.com/Kr328/ClashForAndroid/releases','_blank')
}
window.$gotocfwgithub = () => {
    window.open('https://github.com/Fndroid/clash_for_windows_pkg/releases', '_blank')
}
window.$gotoclashxithub = () => {
    window.open('https://github.com/yichengchen/clashX/releases', '_blank')
}
window.$importtoshadowrocket = async()=>{
    var uri = `${gconfig.$subscruribase}/v3/subscr?id=${usubscrid.value}`
    location.href = `shadowrocket://add/sub://${Base64.encode(uri)}/?remark=${encodeURIComponent(gconfig.$sitename)}`
}

</script>


<style src="./../assets/common.css" scoped></style>
<style scoped>

.docitem{
    display:block;
    margin:0;
    padding:15px 20px;
    text-align: left;
}
.docitem:hover{
    background-color: #f6f7fb;
}
.docintro{
    margin:0;
    padding:0;
}
.docitemtit {
    font-size:16px;
    font-weight: bold;
    color:#333333;
}
.docitemdec {
    padding-top: 8px;
    font-size:13px;
    color:#999999;
}
.custclose{
    cursor: pointer;
    color:#999999;
}
.custclose:hover{
    color:#666666;
}
</style>